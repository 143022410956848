// Import necessary functions from redux and redux-thunk
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

// Import the reducer from NavHeaderDucks
import navHeaderReducer from '../ducks/NavHeaderDucks';
import notificationReducer from '../ducks/NotificationDucks';
import containerTrackReducer from '../ducks/ContainerTrackDucks';
import ChargesReducer from '../ducks/Charges';

// Import necessary functions from redux-persist
import { persistStore, persistReducer } from 'redux-persist';

// Import default storage from redux-persist
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// Combine all reducers into a single root reducer
const rootReducer = combineReducers({
  modules: navHeaderReducer,
  notifications: notificationReducer,
  containerTrackRedux: containerTrackReducer,
  charges: ChargesReducer
});

// Define configuration for redux-persist
const persistConfig = {
  key: 'root',
  storage
  // blacklist: ['containerTrackRedux']
};

// Create a persisted reducer using the root reducer and the persist configuration
const persistedReducer = persistReducer(persistConfig, rootReducer);
// const persistedReducer = persistReducer(persistConfig, rootReducer, {
//   blacklist: ['containerTrackRedux'],
// });

// Use compose to apply middleware to the store
const allStoreEnhancers = compose(
  applyMiddleware(thunk)
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// Create the Redux store using the persisted reducer and the enhancer
const store = createStore(persistedReducer, allStoreEnhancers);
// const store=createStore(rootReducer, allStoreEnhancers);
// Create a persistor using the store
const persistor = persistStore(store);

// Export the store and persistor so they can be used elsewhere in the app
export { store, persistor };
